.l-header{
    @include sp{

    }
    &_container{
        padding: 10px $pad-lg;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #aaa;
        @include sp{
            padding: 10px $pad-sm;
            align-items: center;
            height: 70px;
            box-sizing: border-box;
            .is-header-open &{
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                background: $base-bg-color;
                z-index: 100;
            }
        }
    }
    &_left{
        display: flex;
        &--logo{
            img{
                width: 100px;
                @include sp{
                    width: 60px;
                }
            }
        }
        &--list{
            margin-left: 40px;
            padding-top: 20px;
            display: flex;
            @include sp{
                display: none;
            }
            &_item{
                margin-right: 10px;
                a{
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
    }
    &_right{
        &--list{
            display: flex;
            padding-top: 20px;
            @include sp{
                display: none;
            }
            &_item{
                margin-right: 10px;
            }
        }
        &--spBtn{
            display: none;
            @include sp{
                display: block;
                width: 30px;
                height: 30px;
                position: relative;
            }
            &_bar{
                width: 100%;
                height: 2px;
                background-color: #000;
                display: inline-block;
                margin: 0 0 10px;
                line-height: 1;
                position: absolute;
                left: 0;
                transition: transform 0.3s;
                &:first-of-type{
                    top: 0;
                    .is-header-open &{
                        transform: translateY(13px) rotate(45deg);
                    }
                }
                &:nth-of-type(2){
                    top: 14px;
                    .is-header-open &{
                        display: none;
                    }
                }
                &:last-of-type{
                    bottom: 0;
                    margin-bottom: 0;
                    .is-header-open &{
                        transform: translateY(-15px) rotate(-45deg);
                    }
                }
            }
        }
    }
    &_spMenu{
        display: none;
        @include sp{
            .is-header-open &{
                display: block;
                position: fixed;
                top: 70px;
                left: 0;
                width: 100%;
                height: 100vh;
                z-index: 100;
                background: #eee;
            }
        }
        &--title{
            font-size: 20px;
            margin: 0 0 20px;
        }
        &--list{
            &_item{
                a{
                    font-size: 18px;
                    display: block;
                    margin: 0 0 10px;
                    .is-home &{
                        font-size: 22px;
                        font-weight: bold;
                    }
                }
            }
        }
        .react-tabs{
            &__tab{
                width: 33%;
                margin: 0;
                box-sizing: border-box;
                text-align: center;
                font-weight: bold;
                color: #666;
                &--selected{
                    color: #000;
                    background: #eee;
                }
                &-list{
                    background-color: #aaa;
                }
                &-panel{
                    padding: 20px $pad-sm;
                }
            }
        }
    }
}
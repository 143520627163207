.l-footer{
    &_main{
        padding: 10px $pad-lg 20px;
        background-color: #bbb;
        @include sp{
            display: none;
        }
        &--catlist{
            display: flex;
            justify-content: center;
            @include sp{
                display: block;
            }
            &_item{
                &:not(:first-of-type){
                    margin-left: 80px;
                    @include sp{
                        margin-left: 0;
                    }
                }
                &--title{
                    font-size: 18px;
                    font-weight: bold;
                }
                &--container{
                    display: flex;
                    @include sp{
                        display: block;
                    }
                }
            }
        }
        &--catsublist{
            & + &{
                margin-left: 30px;
                @include sp{
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
            &_item{
                &:not(:first-of-type){
                    margin-top: 10px;
                }
            }
        }
        &--snslist{
            display: flex;
            justify-content: center;
            margin-top: 80px;
            &_item{
                &:not(:first-of-type){
                    margin-left: 10px;
                }
            }
        }
    }
    &_bottom{
        padding: 20px $pad-lg 10px;
        background-color: #666;
        color: #fff;
        @include sp{
            padding: 20px $pad-sm 10px;
        }
        &--list{
            display: flex;
            justify-content: center;
            @include sp{
                display: block;
            }
            &_item{
                position: relative;
                &:not(:first-of-type){
                    margin-left: 20px;
                    @include sp{
                        margin-left: 0;
                        margin-top: 10px;
                    }
                    &::before{
                        position: absolute;
                        top: 3px;
                        left: -10px;
                        content:"";
                        width: 1px;
                        height: 16px;
                        background-color: #fff;
                        transform: rotate(30deg);
                        @include sp{
                            display: none;
                        }
                    }
                }
            }
        }
        &--copyright{
            text-align: center;
            &_notes{
                font-size: 12px;
            }
        }
    }
}
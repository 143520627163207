.c-form{
    width: 60%;
    max-width: 800px;
    margin: auto;
    @include sp{
        width: 100%;
        max-width: 100%;
    }
    dl{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include sp{
            display: block;
        }
        dt{
            flex-basis: 25%;
            margin-bottom: 20px;
            @include sp{
                margin-bottom: 10px;
            }
        }
        dd{
            flex-basis: 75%;
            margin-inline-start: 0;
            margin-bottom: 20px;
            @include sp{
                display: flex;
            }
        }
    }
    input[type="text"],
    input[type="email"],
    input[type="password"]{
            padding: 10px;
    }
    input[type="text"]{
        @include sp{
            width: 49%;
        }
        &:last-of-type{
            margin-left: 10px;
            @include sp{
                margin: 0;
            }
        }
    }
    input[type="email"]{
        width: 360px;
        @include sp{
            width: 100%;
        }
    }
    input[type="password"]{
        @include sp{
            width: 100%;
        }
    }
    select{
        padding: 10px;
        display: inline-block;
        &:not(:first-of-type){
            margin-left: 10px;
        }
    }
    input[type="radio"]{
        display: none;
        & + label{
            display: inline-block;
            margin: 0 20px 0 30px;
            position: relative;
            &::before{
                position: absolute;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background-color: #fff;
                border: 1px solid #aaa;
                content: "";
                top: 2px;
                left: -30px;
            }
        }
        &:checked + label{
            &::after{
                position: absolute;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #999;
                content: "";
                top: 6px;
                left: -26px;
            }
        }
    }

}
.c-btn{
    &_area{
        text-align: center;
    }
    &_more{
        display: inline-block;
        border: 2px solid #999;
        border-radius: 40px;
        padding: 20px 40px;
        &:hover{
            text-decoration: none;
            background-color: #999;
            color: #fff;
        }
    }
    &_toCart{
        display: flex;
        justify-content: space-between;
        &--addCart{
            display: block;
            width: calc(100% - 90px);
            background-color: #000;
            color: #fff !important;
            font-size: 24px;
            text-align: center;
            padding: 20px 0;
            box-sizing: border-box;
        }
        &--addFav{
            display: block;
            width: 75px;
            height: 75px;
            font-size: 20px;
            text-align: center;
            padding: 20px 0;
            box-sizing: border-box;
            border: 2px solid #aaa;
        }
    }
    &_cartGroups{
    }
    &_cartGroup{
        display: block;
        padding: 10px 0;
        font-size: 20px;
        
        text-align: center;
        border: 1px solid #000;
        &:hover{
            text-decoration: none;
        }
        &.stepPurchase{
            background-color: #000;
            font-weight: bold;
            color: #fff;
        }
        &.continueShopping{
            margin-top: 10px;
            font-size: 16px;
        }
    }
    &_extract{
        padding: 10px 0;
        border: 1px solid #666;
        width: 47%;
        text-align: center;
        &.is-submit{
            background-color: #666;
            color: #fff;
            font-weight: bold;
        }
        &--area{
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
        }
    }
}
.l-main{
    &_container{
        margin: 0 $pad-lg 25vh;
        @include sp{
            margin: 0 $pad-sm 25vh;
        }
        &--content{
            &_title{
                padding: 200px 0 100px;
                font-size: 100px;
                text-align: center;
            }
            &_text{
                font-size: 20px;
                text-align: center;
            }
        }
    }
    &_textBanner{
        background-color: #666;
        color: #fff;
        width: 100%;
        height: 60px;
        @include sp{
           height: 40px;
        }
        &--list{
            &_item{
                padding: 20px;
                text-align: center;
                line-height: 1.5;
                box-sizing: border-box;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @include sp{
                    padding: 10px;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
/*-- fonts --*/
$font-en: 'Play', sans-serif;
$font-jp: 'Noto Sans JP', sans-serif;
$base-font-family: 'Play', 'Noto Sans JP', HelveticaNeue, YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
$fa-font-path: "../webfonts";

/*-- colors --*/
$font-color: #0A0908;
$base-bg-color: #F2F4F3;

/*-- breakpoint --*/
$page-max-width: 1280px;
$sp-width: 767px;

/*-- margin --*/
$pad-lg: 30px;
$pad-sm: 3%;
.p-ecSignup{
    &_content{
        max-width: $page-max-width;
        margin: auto;
        @include sp{
            max-width: 100%;
        }
        &--title{
            font-size: 32px;
            text-align: center;
        }
        &--subtitle{
            font-size: 20px;
            margin: 60px auto 20px;
            text-align: center;
        }
        &--lead{
            font-size: 18px;
            text-align: center;
            margin-bottom: 80px;
            @include sp{
                font-size: 14px;
                text-align: left;
                padding: 0 10px;
            }
        }
        &--terms{
            width: 70%;
            height: 200px;
            margin: 0 auto 60px;
            padding: 20px;
            border: 1px solid #aaa;
            overflow-y: scroll;
            box-sizing: border-box;
            @include sp{
                width: 100%;
            }
            &_title{
                font-size: 14px;
                margin: 10px 0;
            }
            &_text{
                font-size: 12px;
                line-height: 1.5;
            }
        }
    }
}
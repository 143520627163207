.c-breadcrumb{
    margin: 10px 0;
    display: flex;
    &_item{
        display: inline-block;
        position: relative;
        &:not(:first-of-type){
            margin-left: 20px;
            &::before{
                position: absolute;
                top: 0;
                left: -15px;
                content:"\03e";
            }
        }
        span{
            font-size: 14px;
        }
    }
}